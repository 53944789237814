import { takeLatest, all } from "redux-saga/effects";
import { SAGA_ACTIONS } from "../_config";

import { getSpotifyAccessToken, loginUser, updatePassword } from "./userSaga";

import { getMenuList } from "./commonSaga";

import { getCmsContent } from "./cmsSaga";
import {
  getCities,
  getCountries,
  getOffers,
  getPlaylists,
  getStates,
  getVenueDetails,
  getVenueList,
  statusChange,
  updateCurrentPlay,
  updateVenuePlaylist,
  updateVenueProfile,
} from "./venueSaga";

export function* sagas() {
  yield all([
    // user saga
    takeLatest(SAGA_ACTIONS.USER.LOGIN, loginUser),
    takeLatest(SAGA_ACTIONS.USER.UPDATE_PASSWORD, updatePassword),
    takeLatest(SAGA_ACTIONS.USER.GET_SPOTIFY_ACCESS_TOKEN, getSpotifyAccessToken),

    //COMMON Saga
    takeLatest(SAGA_ACTIONS.COMMON.MENU_ITEMS, getMenuList),

    //CMS Saga
    takeLatest(SAGA_ACTIONS.CMS.GET_CMS, getCmsContent),

    //Venue Saga
    takeLatest(SAGA_ACTIONS.VENUE.GET_VENUES, getVenueList),
    takeLatest(SAGA_ACTIONS.VENUE.GET_VENUE_DETAILS, getVenueDetails),
    takeLatest(SAGA_ACTIONS.VENUE.UPDATE_VENUE_PLAYLIST, updateVenuePlaylist),
    takeLatest(SAGA_ACTIONS.VENUE.UPDATE_VENUE, updateVenueProfile),
    takeLatest(SAGA_ACTIONS.VENUE.GET_PLAYLISTS, getPlaylists),
    takeLatest(SAGA_ACTIONS.COMMON.COUNTRIES, getCountries),
    takeLatest(SAGA_ACTIONS.COMMON.STATES, getStates),
    takeLatest(SAGA_ACTIONS.COMMON.CITIES, getCities),
    takeLatest(SAGA_ACTIONS.VENUE.GET_OFFERS, getOffers),
    takeLatest(SAGA_ACTIONS.VENUE.STATUS_CHANGE, statusChange),
    takeLatest(SAGA_ACTIONS.VENUE.UPDATE_CURRENT_PLAY, updateCurrentPlay),
  ]);
}
