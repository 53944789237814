// @ts-nocheck
import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { URLS } from "./_config";
import requireNoAuth from "./_common/hoc/reqNoAuth";
import requireAuth from "./_common/hoc/reqAuth";
import reqLayout from "./_common/hoc/layout";
import VenueDetails from "./containers/venueDetails";
import Login from "./containers/auth/login";
import Page404 from "./containers/page-404";
import Settings from "./containers/settings";
import CacheBuster from "./CacheBuster";
import Offer from "./containers/offer";
import VenueList from "./containers/venueList";
import SpotifyAuthComponent from "./components/auth/spotifyAuth";
import UpdatePassword from "./containers/updatePassword";

function App() {
  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }
        return (
          <React.Fragment>
            <Router>
              <Switch>
                <Route exact path={URLS.LANDING} component={() => <Redirect to={URLS.VENUES} />} />
                <Route exact path={URLS.VENUE_DETAILS} component={requireAuth(VenueDetails)} />
                <Route exact path={URLS.LOGIN} component={requireNoAuth(Login)} />
                <Route exact path={URLS.SETTINGS} component={requireAuth(Settings)} />
                <Route exact path={URLS.UPDATE_PASSWORD} component={requireAuth(UpdatePassword)} />
                <Route exact path={URLS.OFFER} component={reqLayout(Offer)} />
                <Route exact path={URLS.VENUES} component={requireAuth(VenueList)} />
                <Route exact path={URLS.SPOTIFYAUTH} component={requireAuth(SpotifyAuthComponent)} />
                <Route path="*" component={reqLayout(Page404)} />
              </Switch>
            </Router>
          </React.Fragment>
        );
      }}
    </CacheBuster>
  );
}

export default App;
