import { call, put } from "redux-saga/effects";
import { API_URL, ACTIONS, sagaFunction } from "../_config";
import { CallApi } from "./api/callApi";

export function* getVenueList(action: any): any {
  // const yieldPuts = [
  //     { type: ACTIONS.EVENT.GET_EVENT_GAME_DETAILS},
  //     { type: ACTIONS.EVENT.GET_STATUS_MESSAGE, payload: "status" },
  //     { type: ACTIONS.EVENT.RODEO_EVENT_LOGIN_REQUIRED, payload: "rodeo_event_dtls.login_required" },
  //     { type: ACTIONS.EVENT.SITE_HEADER, payload: "rodeo_event_game_info.enable_header" },
  // ]
  yield call(sagaFunction, action, CallApi.GET, API_URL.VENUE.GET_VENUES, action.payload, true);
}

export function* getVenueDetails(action: any): any {
  yield call(sagaFunction, action, CallApi.GET, API_URL.VENUE.GET_VENUE_DETAILS + action.payload.venue_id, {}, true);
}

export function* getOffers(action: any): any {
  yield call(sagaFunction, action, CallApi.GET, API_URL.VENUE.GET_OFFERS + action.payload.venue_id, {}, true);
}

export function* updateVenuePlaylist(action: any): any {
  yield call(
    sagaFunction,
    action,
    CallApi.POST,
    API_URL.VENUE.UPDATE_VENUE_PLAYLIST + action.payload.venue_id,
    action.payload,
    true
  );
}

export function* updateVenueProfile(action: any): any {
  yield call(
    sagaFunction,
    action,
    CallApi.POST,
    API_URL.VENUE.UPDATE_VENUE + action.payload.venue_id,
    action.payload,
    true
  );
}

export function* getPlaylists(action: any): any {
  yield call(sagaFunction, action, CallApi.GET, API_URL.VENUE.GET_PLAYLISTS + action.payload.venue_id, {}, true);
}

export function* getCountries(action: any): any {
  yield call(sagaFunction, action, CallApi.GET, API_URL.COMMON.COUNTRIES, {}, true);
}

export function* getStates(action: any): any {
  yield call(
    sagaFunction,
    action,
    CallApi.GET,
    API_URL.COMMON.STATES + "/" + action.payload.countryId + "/" + action.payload.type,
    {},
    true
  );
}

export function* getCities(action: any): any {
  yield call(sagaFunction, action, CallApi.GET, API_URL.COMMON.CITIES + "/" + action.payload.stateId, {}, true);
}

export function* statusChange(action: any): any {
  yield call(
    sagaFunction,
    action,
    CallApi.POST,
    API_URL.VENUE.STATUS_CHANGE + action.payload.venue_id,
    action.payload,
    true
  );
}

export function* updateCurrentPlay(action: any): any {
  yield call(sagaFunction, action, CallApi.POST, API_URL.VENUE.UPDATE_CURRENT_PLAY, action.payload, true);
}
