import { combineReducers } from "redux";
import loaderReducer from "./common/loaderReducer";
import globalsReducer from "./common/globalsReducer";
import userReducer from "./user/userReducer";
import eventReducer from "./event/eventReducer";

const rootReducer = combineReducers({
  loader: loaderReducer,
  user: userReducer,
  event: eventReducer,
  global: globalsReducer,
});

export default rootReducer;
