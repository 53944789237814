let apiBaseUrl = "";
let socketUrl = "";

let siteUrl = "";
let appStage = process.env.REACT_APP_STAGE;

if (appStage === "dev") {
  siteUrl = "http://localhost:3030";
  apiBaseUrl = "https://choonapp-backend.dreamztesting.com/api/v1/";
} else if (appStage === "prod") {
  apiBaseUrl = "https://www.profantasyrodeo.com";
  apiBaseUrl = "https://controlcenter.profantasyrodeo.com/api/v1/";
} else if (appStage === "uat") {
  siteUrl = "https://pfrnew.dreamztesting.com";
  apiBaseUrl = "https://pfrnew-backend.dreamztesting.com/api/v1/";
}

export const API_BASE_URL = apiBaseUrl;
export const SOCKETURL = socketUrl;

export const SITE_URL = siteUrl;
