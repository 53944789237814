import { call, put } from "redux-saga/effects";
import { API_URL, ACTIONS } from "../_config";
import { CallApi } from "./api/callApi";

export function* getMenuList(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.COMMON.MENU_ITEMS, data, true);
    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data.data) {
      yield put({
        type: ACTIONS.COMMON.MENU_LIST,
        payload: resp.data,
      });
      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e: any) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}
