import React from "react";

const LazyRodeoEvent = React.lazy(() => import("./offer"));

const Offer = (props: Record<string, any>) => (
  <React.Suspense fallback={<div className="transition-loader">Loading...</div>}>
    <LazyRodeoEvent {...props} />
  </React.Suspense>
);

export default Offer;
