import { ActionExtended } from "src/_common/interfaces/ActionExtended";
import { User } from "src/_common/interfaces/models/user";
import { ACTIONS, SPOTIFY_ACCESS_TOKEN, SPOTIFY_REFRESH_TOKEN, STORAGE } from "src/_config";

export interface UserReducer {
  user: User | null;
  token: string | null;
  isAuthenticated: boolean;
}

const initialState: UserReducer = {
  user: null,
  token: null,
  isAuthenticated: false,
};

const userReducer = (state = initialState, action: ActionExtended) => {
  switch (action.type) {
    case ACTIONS.USER.LOGIN:
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        isAuthenticated: true,
      };
    case ACTIONS.USER.LOGOUT:
      localStorage.removeItem(STORAGE);
      localStorage.removeItem(SPOTIFY_ACCESS_TOKEN);
      localStorage.removeItem(SPOTIFY_REFRESH_TOKEN);
      return {
        ...state,
        user: null,
        token: null,
        isAuthenticated: false,
      };
    case ACTIONS.USER.ME:
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true,
      };
    // case ACTIONS.USER.ONLINE_STATUS_CHANGE:
    //   let tempStatus = {
    //     onlineStatus: action.payload
    //   }
    //   var newUser = { ...state.user, ...tempStatus }
    //   return {
    //     ...state,
    //     user: newUser,
    //   };
    // case ACTIONS.USER.PROFILE:
    //   return {
    //     ...state,
    //     user: action.payload,
    //     isAuthenticated: true,
    //   }

    // case ACTIONS.USER.UPDATE_DISPLAY_NAME:
    //   let displayName = action.payload
    //   let editedUser = {...state.user}
    //   //editedUser["display_name"] = displayName
    //   return{
    //     ...state,
    //     user: editedUser
    //   }

    default:
      return state;
  }
};

export default userReducer;
