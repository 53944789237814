import { any } from "prop-types";
import { ActionExtended } from "src/_common/interfaces/ActionExtended";
import { ACTIONS } from "src/_config";
export interface EventReducer {
  getMenuList: any;
}

const initialState: EventReducer = {
  getMenuList: null,
};

const eventReducer = (state = initialState, action: ActionExtended) => {
  switch (action.type) {
    case ACTIONS.COMMON.MENU_LIST:
      return {
        ...state,
        getMenuList: action.payload.data,
      };
    default:
      return state;
  }
};

export default eventReducer;
