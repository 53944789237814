import React from "react";

const LazyPage404 = React.lazy(() => import("./page404"));

const Page404 = (props: Record<string, any>) => (
  <React.Suspense fallback={<div className="transition-loader">Loading...</div>}>
    <LazyPage404 {...props} />
  </React.Suspense>
);

export default Page404;
