import { API_BASE_URL } from "./site_urls";

export const APPLICATION_NAME = "Choon App";

export const STORAGE = "Choon";
export const SELECTED_EVENT_STORAGE = `${APPLICATION_NAME}-Event`;
export const TESTPASSWORD = "choon$2024";
export const PAGE_TITLE = `${APPLICATION_NAME} - `;
export const FILE_BASE_PATH = "";
export const APP_VERSION = process.env.REACT_APP_VERSION;
export const APP_STAGE = process.env.REACT_APP_STAGE;

export const SPOTIFY_CLIENT_ID = "14e343a88a604eb8a9c725b4243d6c8c";
export const SPOTIFY_CLIENT_SECRET = "0f4eb0f7bf1449ffb5744af8a1fd3ce6";
export const SPOTIFY_SCOPES = [
  "playlist-read-private",
  "user-read-email",
  "user-read-private",
  "user-modify-playback-state",
  "user-read-playback-state",
  "user-read-currently-playing",
  "streaming",
];
export const SPOTIFY_ACCESS_TOKEN = "spotifyAccessToken";
export const SPOTIFY_REFRESH_TOKEN = "spotifyRefreshToken";
export const SPOTIFY_PLAYLIST_API_URL = "https://api.spotify.com/v1/me/playlists";
export const SPOTIFY_TOKEN_API_URL = "https://accounts.spotify.com/api/token";

export const DATE_FORMAT = "MM/dd/yyyy";

export const DATE_ALL_FORMAT = {
  DATE_PICKER_FORMAT: "MM/dd/yyyy",
  MOMENT_FORMAT: "MM/DD/yyyy",
};

export const CHAT_DATE_TIME_FORMAT = {
  DISPLAY_DATE_WITH_TIME: "Do MMMM, YYYY hh:mm a",
  DISPLAY_DAY_TIME: "dddd hh:mm a",
};
export const TIME_CONFIG = {
  TIME_FORMAT: "hh:mm a",
  TIME_INTERVALS: 5,
};
export const DEFAULT_LATITUDE = "31.9686";
export const DEFAULT_LONGITUDE = "-99.9018";

export const PAGES = {
  LOGIN: "Login",
  SETTINGS: "Settings",
  OFFERS: "Offers",
  VENUES: "Venues",
  VENUE_DETAILS: "Venue Details",
  UPDATE_PASSWORD: "Update Password",
};

export const URLS = {
  LANDING: "/",
  LOGIN: "/login",
  UPDATE_PASSWORD: "/update-password",
  SETTINGS: "/settings/:slug",
  OFFER: "/offers/:slug",
  VENUES: "/venues",
  VENUE_DETAILS: "/venue/:slug",
  SPOTIFYAUTH: "/spotify-auth",
};

export const API_URL = {
  // API URL
  USER: {
    LOGIN: `${API_BASE_URL}auth/login`,
    LOGIN_WITH_OTP: `${API_BASE_URL}login/login-with-otp`,
    LOGOUT: `${API_BASE_URL}auth/logout`,
    DETAILS: `${API_BASE_URL}DATA/profile`,
    UPDATE_PROFILE: `${API_BASE_URL}users/update-user`,
    LOGIN_WITH_USERNAME: `${API_BASE_URL}login/login-with-username`,
    FORGOT_PASSWORD: `${API_BASE_URL}login/forgot-password`,
    DELETE_USER: `${API_BASE_URL}users/delete-user`,
    AUTOLOGIN: `${API_BASE_URL}login/autologin`,
    UPDATE_PASSWORD: `${API_BASE_URL}DATA/venues/update-venue-password`,
    GET_SPOTIFY_ACCESS_TOKEN: `${API_BASE_URL}DATA/users/get-new-spotify-access-token`,
  },
  CMS: {
    GET_CMS: `${API_BASE_URL}common/get-cms`,
  },
  VENUE: {
    GET_VENUES: `${API_BASE_URL}DATA/venues/list`,
    GET_VENUE_DETAILS: `${API_BASE_URL}DATA/venues/get/`,
    GET_OFFERS: `${API_BASE_URL}DATA/venues/get-special-offers/`,
    UPDATE_VENUE_PLAYLIST: `${API_BASE_URL}DATA/venues/update-venue-playlist/`,
    GET_PLAYLISTS: `${API_BASE_URL}DATA/venues/get-playlist/`,
    UPDATE_VENUE: `${API_BASE_URL}DATA/venues/update-venue-profile/`,
    STATUS_CHANGE: `${API_BASE_URL}DATA/venues/status-change/`,
    UPDATE_CURRENT_PLAY: `${API_BASE_URL}DATA/playlists/update-current-play`,
  },
  COMMON: {
    COUNTRY_LIST: `${API_BASE_URL}country/list`,
    MENU_ITEMS: `${API_BASE_URL}common/get-menus`,
    SETTINGS: `${API_BASE_URL}common/get-settings`,
    SPONSOR: `${API_BASE_URL}common/get-sponsors`,
    STATUS_MESSAGE: `${API_BASE_URL}common/get-status`,
    GALLERY: `${API_BASE_URL}common/get-galleries`,
    COUNTRIES: `${API_BASE_URL}common/get-countries`,
    STATES: `${API_BASE_URL}common/get-states`,
    CITIES: `${API_BASE_URL}common/get-cities`,
    RIDERS: `${API_BASE_URL}common/get-riders`,
    TESTIMONIALS: `${API_BASE_URL}common/get-testimonials`,
    BLOCKS: `${API_BASE_URL}common/get-blocks/`,
    WINNERSTORY: `${API_BASE_URL}common/winner-story-list/`,
    CONTACTUS: `${API_BASE_URL}common/contact-us`,
    SEO_LIST: `${API_BASE_URL}common/get-seo-details`,
  },
  PAYMENT: {
    GET_CARD_LIST: `${API_BASE_URL}payments/card`,
    DELETE_CARD: `${API_BASE_URL}payments/delete-card`,
    SET_DEFAULT_CARD: `${API_BASE_URL}payments/set-default-card`,
    MAKE_PAYMENT: `${API_BASE_URL}payments/pay`,
  },
};

export const ACTIONS = {
  // STORE Data in Redux
  LOADER: {
    SET_FP_STATUS: "ACTIONS/LOADER/SET_FP_STATUS",
  },
  GLOBAL: {
    SET_LOGIN_MODAL: "ACTIONS/GLOBAL/SET_LOGIN_MODAL",
  },
  USER: {
    LOGIN: "ACTIONS/USER/LOGIN",
    LOGIN_WITH_OTP: "ACTIONS/USER/LOGIN_WITH_OTP",
    LOGIN_WITH_USERNAME: "ACTIONS/USER/LOGIN_WITH_USERNAME",
    ME: "ACTIONS/USER/ME",
    LOGOUT: "ACTIONS/USER/LOGOUT",
    FORGOT_PASSWORD: "ACTIONS/USER/FORGOT_PASSWORD",
  },
  COMMON: {
    MENU_LIST: "ACTIONS/COMMON/MENU_LIST",
    SETTINGS: "ACTIONS/COMMON/SETTINGS",
    SPONSOR: "ACTIONS/COMMON/SPONSOR",
    BLOCKS: "ACTIONS/COMMON/BLOCKS",
    TEAM_CREATE_PROCESS_STATUS: "ACTIONS/COMMON/TEAM_CREATE_PROCESS_STATUS",
    SEO_LIST: "ACTIONS/COMMON/SEO_LIST",
    REFFERER_URL: "ACTIONS/COMMON/REFFERER_URL",
  },
};

export const SAGA_ACTIONS = {
  // GET Store data from Redux
  USER: {
    LOGIN: "SAGA_ACTIONS/USER/LOGIN",
    LOGIN_WITH_OTP: "SAGA_ACTIONS/USER/LOGIN_WITH_OTP",
    LOGOUT: "SAGA_ACTIONS/USER/LOGOUT",
    DETAILS: "SAGA_ACTIONS/USER/DETAILS",
    UPDATE_PROFILE: "SAGA_ACTIONS/USER/UPDATE_PROFILE",
    LOGIN_WITH_USERNAME: "SAGA_ACTIONS/USER/LOGIN_WITH_USERNAME",
    FORGOT_PASSWORD: "SAGA_ACTIONS/USER/FORGOT_PASSWORD",
    UPDATE_PASSWORD: "SAGA_ACTIONS/USER/UPDATE_PASSWORD",
    DELETE_USER: "SAGA_ACTIONS/USER/DELETE_USER",
    AUTOLOGIN: "SAGA_ACTIONS/COMMON/AUTOLOGIN",
    GET_SPOTIFY_ACCESS_TOKEN: "SAGA_ACTIONS/USER/GET_SPOTIFY_ACCESS_TOKEN",
  },
  VENUE: {
    GET_VENUES: "SAGA_ACTIONS/VENUE/GET_VENUES",
    GET_VENUE_DETAILS: "SAGA_ACTIONS/VENUE/GET_VENUE_DETAILS",
    GET_OFFERS: "SAGA_ACTIONS/VENUE/GET_OFFERS",
    UPDATE_VENUE_PLAYLIST: "SAGA_ACTIONS/VENUE/UPDATE_VENUE_PLAYLIST",
    UPDATE_VENUE: "SAGA_ACTIONS/VENUE/UPDATE_VENUE",
    GET_PLAYLISTS: "SAGA_ACTIONS/VENUE/GET_PLAYLISTS",
    STATUS_CHANGE: "SAGA_ACTIONS/VENUE/STATUS_CHANGE",
    UPDATE_CURRENT_PLAY: "SAGA_ACTIONS/VENUE/UPDATE_CURRENT_PLAY",
  },
  CMS: {
    GET_CMS: "SAGA_ACTIONS/COMMON/GET_CMS",
  },
  COMMON: {
    COUNTRY_LIST: "SAGA_ACTIONS/COMMON/COUNTRY_LIST",
    MENU_ITEMS: "SAGA_ACTIONS/COMMON/MENU_ITEMS",
    SETTINGS: "SAGA_ACTIONS/COMMON/SETTINGS",
    SPONSOR: "SAGA_ACTIONS/COMMON/SPONSOR",
    STATUS_MESSAGE: "SAGA_ACTIONS/COMMON/STATUS_MESSAGE",
    GALLERY: "SAGA_ACTIONS/COMMON/GALLERY",
    COUNTRIES: "SAGA_ACTIONS/COMMON/COUNTRIES",
    STATES: "SAGA_ACTIONS/COMMON/STATES",
    CITIES: "SAGA_ACTIONS/COMMON/CITIES",
    RIDERS: "SAGA_ACTIONS/COMMON/RIDERS",
    TESTIMONIALS: "SAGA_ACTIONS/COMMON/TESTIMONIALS",
    BLOCKS: "SAGA_ACTIONS/COMMON/BLOCKS",
    WINNERSTORY: "SAGA_ACTIONS/COMMON/WINNERSTORY",
    CONTACTUS: "SAGA_ACTIONS/COMMON/CONTACTUS",
    SEO_LIST: "SAGA_ACTIONS/COMMON/SEO_LIST",
  },
  PAYMENT: {
    GET_CARD_LIST: "SAGA_ACTIONS/PAYMENT/GET_CARD_LIST",
    DELETE_CARD: "SAGA_ACTIONS/PAYMENT/DELETE_CARD",
    SET_DEFAULT_CARD: "SAGA_ACTIONS/PAYMENT/SET_DEFAULT_CARD",
    MAKE_PAYMENT: "SAGA_ACTIONS/PAYMENT/MAKE_PAYMENT",
  },
};

export * from "./site_statics";
export * from "./site_urls";
export * from "./functions";
export * from "./canvasUtils";
export * from "./card_utils";
